import React from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"

import imageBgFotos from "../images/fondo_factura.svg"

import imageBackground from "../images/background1.png"

import imageBackground2 from "../images/mini-bg-1.png"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import SEO from "../../components/Layout/Seo"
import TablePlan from "../../components/plans/TablePlan"
import { IPageWithPlan } from "../../components/plans/types"
import LayoutPage from "../../components/global/Layout"
import Footer from "../../components/global/Footer"
import { LoginAndRegisterArea } from "../../components/auth/LoginAndRegisterArea"
import { CookiesAlert } from "../../components/Layout/CookiesAlert"

const ButtonWhite = styled.button(() => [
  `
-webkit-box-shadow: 0 0 10px 1px #fff;
box-shadow: 0 0 10px 1px #fff;
`,
  tw`transition mx-auto flex mt-4 bg-white rounded-ultra px-16 py-4 font-bold duration-700 ease-out text-autogestiones-100  text-sm lg:text-base`,
])

const Button = styled.button(() => [
  `
@media (min-width: 768px) {
	max-width: 300px;
}
@media (min-width: 1024px) {
	max-width: 300px;
}
width: 100%;
margin-top: 1.25rem;
margin-left: auto;
margin-right: auto;
color: white;
background-color: #646fff;
transition: background-color 0.7s ease;
padding-top: 0.25rem;
padding-bottom: 0.25rem;

&:hover {
	background-color: #8d94f4;
}
-webkit-box-shadow: 0 0 10px 1px #646fff;
box-shadow: 0 0 10px 1px #646fff;
`,
  tw`px-8 py-3 text-base rounded-ultra `,
])

const IndexPage = ({ data, location }: IPageWithPlan) => {
  const params = new URLSearchParams(location.search);
  const categoryId = params.get("categoryId");
  const canTry = !!+params.get("canTry");
  const showCategories = !!+params.get("showCategories");

  const categories = data.appStoreNode?.ar?.categories || []
  const section = categories?.find(x => x?.id === categoryId)
  const products = data.appStoreNode?.ar?.products || []
  const attributes = data.appStoreNode?.ar?.attributes || []
  const groups = data.appStoreNode?.ar?.groups || []
  const plans = (data?.appStoreNode?.ar?.plans || [])
  const tryPlanId = canTry ? data.appStoreNode?.ar?.tryPlanId : null

  return (
    <>
      <div>
        <main  >
          <div style={{
            width: "100%"
          }}>
            <TablePlan
              showCategories={showCategories}
              hiddenFeatures={false}
              {...{ attributes, categories, products, groups, plans, tryPlanId, defaultCategoryId: section?.id }}
            />
          </div>
        </main>
        <Footer />
        <LoginAndRegisterArea
          key={"basic"}

          targetCountryId={"ARG"} />
      </div>
      <CookiesAlert siteId={"AR"} />

    </>
  )
}

export default IndexPage

export const Head = () =>
  <SEO
    description="Te presentamos la nube de Autogestiones"
    lang="es-AR"
    title="Autogestiones Cloud"
  />



export const query = graphql`
      query {
        appStoreNode {
          ar {
          categories {
            id
            title
            coverUrl
          }
          tryPlanId
          products {
            id
            name
            code
            attributeId
            prices {
              productId
              maxScale
              minScale
              price
              productId
            }
          }
          groups {
              id
              name
              order
              subgroups {
                id
                name
                order
              }
            }
            
            attributes {
              id
              name
              groupId
              order
              valueType
              hint
              tags{
                primary
                hidden
                required
                readOnly
              }
            }
            plans {
              id
              name
              tags
              monthlyPrice
              annualPrice
              annualDiscount
              customerMax
              highlights {
                id
                name
                hint
                value
                valueType
                attributeId         
              }
              order
              categoryId
              appPlanToProducts {
                unlimited
                acceptExcess
                saleLimits
                max
                product {
                  name
                  code
                  attributeId
                }
              }
              features {
                name
                value
                attributeId         
              }
            }
          }
        }
      }
      `;
